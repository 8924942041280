import { GetStaticPaths, GetStaticProps } from "next";
import { FunctionComponent } from "react";
import {
  CategoryCarousel,
  SuperHero,
} from "@bluebottlecoffee/design-system/components";

import { useLiveQuery } from "next-sanity/preview";
import {
  TranslationGroupSanityType,
  buildTranslationGroupQuery,
  toCopyObject,
} from "../../../lib/sanity/translation-group-queries";
import { shippingCopyQuery } from "../../../lib/sanity/product-queries";
import { quickShopCarouselCardQuery } from "../../../lib/sanity/quick-shop-carousel-queries";
import { isFeatureEnabled } from "../../../lib/utils/is-feature-enabled";
import { MiniCartDialog } from "../../../components/MiniCartDialog";
import { toCategoryCarouselProps } from "../../../lib/transformers/category-carousel";
import sanityClient from "../../../lib/sanity-client";
import renderModule from "../../../lib/render-module";
import { toSuperHeroProps } from "../../../lib/transformers/super-hero";
import { LayoutWrapper } from "../../../components/LayoutWrapper";
import { YotpoReviewsCarousel } from "../../../components/YotpoReviews";
import {
  getDefaultStaticPaths,
  getDefaultStaticPropsMiniCart,
} from "../../../lib/utils";
import { Homepage, ShippingCopy } from "../../../lib/sanity-schema";
import { CartBaseProps } from "../../../lib/sanity/shared";
import { filters } from "../../../lib/sanity/utils";
import { ConversionCopy } from "./product/[slug]";
import { Dialect } from "../../../lib/utils/locale";
import { globalModulesProjection } from "../../../lib/sanity/global-modules";

interface PageData extends CartBaseProps {
  draftMode?: boolean;
  homepage: Homepage;
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
}

export const homepageQuery = (lang: Dialect["lang"]) => `
*[
    ${filters.whereType("homepage")}
  ]{
    ...,
    ${"modules"}[]{
      ...,
      ${quickShopCarouselCardQuery(lang)},
      ${globalModulesProjection(lang)}
    }
  }|order(priority desc, _updatedAt desc)[0]
`;

export const getStaticProps: GetStaticProps<PageData> = async (context) => {
  const { props: defaultProps } = await getDefaultStaticPropsMiniCart(context);
  const { lang } = defaultProps;
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  const token =
    environment !== "production" ? process.env.SANITY_STUDIO_TOKEN : "";
  const sanityCli = sanityClient();

  const homepage = await sanityCli.fetch<Homepage>(homepageQuery(lang));

  const translationGroupQuery = buildTranslationGroupQuery(lang, "Conversion");

  const [shippingCopy, conversionCopy] = await Promise.all([
    sanityCli.fetch<ShippingCopy>(shippingCopyQuery),
    sanityCli
      .fetch<TranslationGroupSanityType>(translationGroupQuery)
      .then((translations: TranslationGroupSanityType) =>
        toCopyObject<ConversionCopy>(lang, translations),
      ),
  ]);

  return {
    props: {
      homepage,
      shopcardCopy: {
        copy: conversionCopy,
        shippingCopy,
      },
      token,
      ...defaultProps,
    },
  };
};

export const getStaticPaths: GetStaticPaths = getDefaultStaticPaths;

function validate(data: Homepage) {
  if (!data.title) {
    throw Error("Homepage must have a title.");
  }
}

const HomePage: FunctionComponent<PageData> = ({
  draftMode,
  homepage,
  shopcardCopy,
  ...layoutWrapperProps
}) => {
  const {
    region,
    lang,
    appPlayLinks,
    flavorProfileCopy,
    navAndCartInfoBanner,
    productRecs,
    giftCardFormCopy,
    cart,
    subscribableProducts,
    aria,
  } = layoutWrapperProps;

  const homepageProps = draftMode
    ? useLiveQuery<Homepage>(homepage, homepageQuery(lang))[0]
    : homepage;

  validate(homepageProps);

  const dataHomepageCategoryCarouselMobile =
    homepageProps.categoryCarouselMobile?.[0];

  const categoryCarouselProps =
    dataHomepageCategoryCarouselMobile &&
    toCategoryCarouselProps(dataHomepageCategoryCarouselMobile, region, lang);

  const miniCartDialog = (
    <MiniCartDialog
      productRecs={productRecs}
      giftCardFormCopy={giftCardFormCopy}
      miniCartCopy={cart}
      navAndCartInfoBanner={navAndCartInfoBanner}
      region={region}
      lang={lang}
      subscribableProducts={
        isFeatureEnabled(
          process.env.NEXT_PUBLIC_CART_SUBSCRIPTION_TOGGLE_ENABLED,
        )
          ? subscribableProducts
          : []
      }
    />
  );

  return (
    <LayoutWrapper
      draftMode={draftMode}
      {...{
        ...layoutWrapperProps,
        pageTitle: homepageProps.title[lang],
        metaTags: homepageProps.metaTags,
        slideOutContent: [miniCartDialog],
        openMiniCartPanel: Boolean(miniCartDialog),
      }}
      isTransparent={false}
    >
      {dataHomepageCategoryCarouselMobile && (
        <CategoryCarousel
          {...categoryCarouselProps}
          categoryCards={categoryCarouselProps.categoryCards.map((card) => ({
            ...card,
            roundedImage: true,
          }))}
          nElementsToDisplayOnMobile={3}
          leftPadding
          mobileOnly
          isNativeCarousel
        />
      )}

      {homepageProps.hero?.length > 0 && (
        <SuperHero
          heroes={toSuperHeroProps(homepageProps.hero, region, lang).heroes}
          isCarousel
        />
      )}

      {homepageProps.modules?.length > 0 &&
        homepageProps.modules.map((module) =>
          renderModule({
            module,
            region,
            lang,
            appPlayLinks,
            flavorProfileCopy,
            shopcardCopy,
            aria,
          }),
        )}
      <YotpoReviewsCarousel />
    </LayoutWrapper>
  );
};

export default HomePage;
